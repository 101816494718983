import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import React, { ReactNode } from 'react';
import Button from '../Button';

export type FormWrapperProps = {
  children: ReactNode;
  formik: FormikProps<any>;
  className?: string;
  submitBtnText?: string;
  onCancel?: (event?: any) => void;
  isLoading?: boolean;
  btnsAlign?: 'center' | 'start' | 'end';
  customBtns?: ReactNode;
  hideActionButtons?: boolean;
};

type IFormContext<T> = {
  formik?: FormikProps<T>;
};

export const FormContext = React.createContext<IFormContext<any>>({
  formik: undefined
});

const FormWrapper = (props: FormWrapperProps) => {
  return (
    <form
      onSubmit={props.formik.handleSubmit}
      className={`${props.className} flex flex-col gap-y-2`}
    >
      <FormContext.Provider
        value={{
          formik: props.formik
        }}
      >
        {props.children}
      </FormContext.Provider>
      {!props.hideActionButtons && (
        <div
          className={`mt-5 flex gap-2 justify-${props.btnsAlign ?? 'start'}`}
        >
          <Button
            type="submit"
            text={props.submitBtnText ?? 'Save'}
            icon={faCheck}
            size="sm"
            isLoading={props.isLoading}
          />
          {props.customBtns}
          {props.onCancel && (
            <button
              type="button"
              className="text-red-500 sans-serif"
              onClick={props.onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      )}
    </form>
  );
};

export default FormWrapper;
