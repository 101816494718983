import { useLocation } from '@reach/router';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import SEO from '../../components/Seo';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import { FormInput, Label } from '../common/FormInput';
import EventApplicationPoliciesModal from '../EventApplicationPoliciesModal';
import EventDetails from '../EventDetails';
import {
  createEventPurchase,
  EventPurchaseData,
  getEvent,
  getEventApplicationByEventId
} from '../members/api';
import { OtherUpcomingEvents } from '../OtherUpcomingEvents';
import SignInModal from '../SignInModal';
import Card from './../../components/common/Card';
import Layout from './../../components/Layout';
import './../layout.css';
import './EventLayout.css';

export enum EVENT_APPLICATION_STATES {
  IDLE = 'IDLE',
  EXISTS = 'EXISTS',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST'
}

const EventPurchaseSchema = Yup.object().shape({
  seats: Yup.number()
    .required('Number of seats is required')
    .min(1, 'Must purchase at least 1 seat')
    .max(100, 'Cannot purchase more than 100 seats'),
  paymentMethod: Yup.string().required('Payment method is required'),
  eventId: Yup.string().required('Event ID is required')
});

const EventLayout: React.FC = ({ pageContext }: any) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { pageData, otherUpcomingEvents } = pageContext;

  const [event, setEvent] = useState<any>(null);
  const [isLoadingEvent, setLoadingEvent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    showEventApplicationPoliciesModal,
    setShowEventApplicationPoliciesModal
  ] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [eventApplicationExists, setEventApplicationExists] = useState({
    status: EVENT_APPLICATION_STATES.IDLE,
    id: ''
  });

  const viewApplication = (id: string) => {
    navigate(`/members/events/applications`);
  };

  const checkEventApplicationIfExists = async () => {
    const result = await getEventApplicationByEventId(
      pageData.managementEventId
    );
    if (!!result.eventApplicationId) {
      setEventApplicationExists({
        status: EVENT_APPLICATION_STATES.EXISTS,
        id: result.eventApplicationId
      });
    } else {
      setEventApplicationExists({
        status: EVENT_APPLICATION_STATES.DOES_NOT_EXIST,
        id: ''
      });
    }
  };

  useEffect(() => {
    if (pageData.managementEventId) {
      if (!!auth.authState) {
        setLoadingEvent(true);
        getEvent(pageData.managementEventId)
          .then(result => {
            setEvent(result.event);
          })
          .finally(() => {
            setLoadingEvent(false);
          });
      }
    }
  }, [pageData.managementEventId, auth.authState]);

  useEffect(() => {
    if (eventApplicationExists.status === EVENT_APPLICATION_STATES.IDLE) {
      checkEventApplicationIfExists();
    }
  }, [eventApplicationExists.status]);

  useEffect(() => {
    if (
      !!auth.authState &&
      location.search.indexOf('applyNow=true') !== -1 &&
      eventApplicationExists.status === EVENT_APPLICATION_STATES.DOES_NOT_EXIST
    ) {
      openPoliciesModal();
    }
  }, [location, eventApplicationExists]);

  const openPoliciesModal = async () => {
    if (pageData.managementEventId) {
      if (!!auth.authState) {
        const result = await getEvent(pageData.managementEventId);
        setEvent(result.event);
        setShowEventApplicationPoliciesModal(true);
      } else {
        setShowSignInModal(true);
      }
    } else {
      // no-op since no legacy events from manage
      // window.location.href = `${process.env.GATSBY_LEGACY_SITE_URL}/member/events/rules-and-regulations?eventID=${pageData.legacySiteEventId}`;
    }
  };

  const navigateToSignIn = () => {
    navigate(`/auth/login?intended=${location.pathname}?applyNow=true`);
  };

  return (
    <Layout>
      <SEO title={pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 mr-10">
            <Card>
              <h1 className="font-bold text-2xl text-gray-900 mb-6">
                {pageData.title}
              </h1>
              <div className="mb-6">
                <EventDetails
                  details={{
                    startDate: new Date(pageData.startDate),
                    endDate: new Date(pageData.endDate),
                    grades: pageData.grades,
                    price: pageData.price,
                    location: pageData.location || 'Online (Zoom)'
                  }}
                />
              </div>
              <div className="content-body">
                {!!pageData.shouldHaveApplyButton &&
                  !!pageData.applicationFormLink && (
                    <div className="mb-4">
                      <a href={pageData.applicationFormLink} target="_blank">
                        <Button text="Apply Now" />
                      </a>
                    </div>
                  )}
                <ContentfulContent
                  content={pageData.description?.description}
                />
              </div>
            </Card>
          </div>
          <div className="w-full lg:w-1/3 mt-6 lg:mt-0">
            {!!pageData.shouldHavePurchaseButton && (
              <section className="mb-8">
                {auth.authState && !!pageData.managementEventId ? (
                  <section className="p-4 border border-gray-200 rounded-md bg-gray-100 shadow-md">
                    <p className="text-gray-600 mb-4">
                      Please select the number of seats you would like to
                      purchase.
                    </p>
                    <Formik
                      validationSchema={EventPurchaseSchema}
                      initialValues={{
                        seats: 1,
                        paymentMethod: 'CREDIT_CARD',
                        eventId: pageData.managementEventId
                      }}
                      onSubmit={async values => {
                        try {
                          setIsSubmitting(true);
                          const { eventPurchase } = await createEventPurchase(
                            values as EventPurchaseData
                          );
                          setIsSubmitting(false);
                          navigate(
                            `/members/events/purchases/${eventPurchase.id}`
                          );
                        } catch (err) {
                          toast.error(
                            'Something went wrong. Please contact support for assistance.'
                          );
                        }
                      }}
                    >
                      {({ setFieldValue }) => (
                        <Form>
                          <section className="grid grid-cols-2 gap-4">
                            <div>
                              <div className="my-1.5">
                                <Label text="Number of Seats" />
                              </div>
                              <FormInput
                                size="md"
                                type="number"
                                placeholder="Number of Seats"
                                name="seats"
                                ariaLabel="Number of Seats"
                              />
                            </div>
                            <div className="mt-2">
                              <div className="mb-1">
                                <Label text="Payment Method" />
                              </div>
                              <Select
                                name="paymentMethod"
                                ariaLabel="Payment Method"
                                defaultValue={{
                                  value: 'CREDIT_CARD',
                                  label: 'Credit Card'
                                }}
                                options={[
                                  {
                                    value: 'CREDIT_CARD',
                                    label: 'Credit Card'
                                  },
                                  {
                                    value: 'PURCHASE_ORDER',
                                    label: 'Purchase Order'
                                  }
                                ]}
                                onChange={option => {
                                  setFieldValue('paymentMethod', option?.value);
                                }}
                              />
                            </div>
                          </section>
                          <div className="mt-4">
                            <p className="text-sm text-gray-500 italic">
                              Need a quote document? Proceed to the next screen
                              for download.
                            </p>
                          </div>
                          <div className="mt-6">
                            <Button
                              type="submit"
                              text="Purchase Now"
                              isLoading={isSubmitting}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </section>
                ) : (
                  <section className="p-4 border border-gray-200 rounded-md bg-gray-100 shadow-md">
                    <p className="text-gray-600 mb-4">
                      To purchase tickets for this event, please login to your
                      account.
                    </p>
                    <Button
                      text="Login to Purchase"
                      size="sm"
                      onClick={() => {
                        const path = window.location.pathname;
                        navigate(`/auth/login?intended=${path}`);
                      }}
                    />
                  </section>
                )}
              </section>
            )}
            <h2 className="text-primary mb-4 font-bold text-xl">
              Other Upcoming Events
            </h2>
            <OtherUpcomingEvents upcomingEvents={otherUpcomingEvents} />
          </div>
        </div>
      </ContentContainer>
      <EventApplicationPoliciesModal
        policy={event?.policy}
        eventId={pageData.managementEventId}
        isOpen={showEventApplicationPoliciesModal}
        onClose={() => setShowEventApplicationPoliciesModal(false)}
      />
      <SignInModal
        isOpen={showSignInModal}
        onClose={() => setShowSignInModal(false)}
        navigateToSignin={navigateToSignIn}
        content="Please log in to apply to this event"
      />
    </Layout>
  );
};

export default EventLayout;
