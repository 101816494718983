import {
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { getGradesTCAL, getPositionsTCAL } from '../../tcal-api';
import { FormField, FormWrapper } from '../common/Form';
import { createEventIntake, submitEventIntake } from './api';
import { QueryReturnState } from './Enum';
import { IGrade, IPosition, ISeatAssignment } from './NewApiTypes';
import Button from '../common/Button';

export type EventIntakeModalType = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  position: string;
  grades: string[];
  focusTopicCourseSelection: string | null;
  fields: {
    [key: string]: string;
  };
};

type IForm = EventIntakeModalType;

interface EventIntakeModalProps {
  seatAssignmentId: string;
  focusTopicCourseOptions: { value: string; label: string }[];
  onClose: () => void;
  intakeConfig: any;
  eventIntakeModalAssignment: ISeatAssignment;
  intakeFields: any[];
  afterSubmit: () => void;
}

const EventIntakeModalSchema = (props: { isCourseSelectionExists: boolean }) =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    position: Yup.string().required('Selection required'),
    grades: Yup.array()
      .min(1, 'Selection required')
      .required('Required'),
    focusTopicCourseSelection: Yup.string().when([], {
      is: () => props.isCourseSelectionExists,
      then: schema => schema.required('Selection required'),
      otherwise: schema => schema.notRequired()
    }),
    fields: Yup.object().optional()
  });

const EventIntakeModal = (props: EventIntakeModalProps) => {
  const { data: gradesData, isFetching: isLoadingGrades } = useQuery<{
    state: QueryReturnState;
    grades?: IGrade[];
  }>('grades', getGradesTCAL);

  const { data: positionsData, isLoading: isLoadingPositions } = useQuery<{
    state: QueryReturnState;
    positions?: IPosition[];
  }>('positions', getPositionsTCAL);

  const getDefaultValues = (): EventIntakeModalType => {
    return {
      firstName: props.eventIntakeModalAssignment.user.firstName || '',
      lastName: props.eventIntakeModalAssignment.user.lastName || '',
      email: props.eventIntakeModalAssignment.user.email || '',
      position:
        props.eventIntakeModalAssignment.intake?.responses?.position || '',
      grades: props.eventIntakeModalAssignment.intake?.responses?.grades || [],
      focusTopicCourseSelection:
        props.eventIntakeModalAssignment.intake?.responses
          ?.focusTopicCourseSelection || '',
      fields: {
        ...props.intakeFields.reduce((acc, field) => {
          acc[field.id] = {
            value:
              props.eventIntakeModalAssignment.intake?.responses?.fields?.[
                field.id
              ]?.value || '',
            label: field.label
          };
          return acc;
        }, {})
      }
    };
  };

  const formik = useFormik<EventIntakeModalType>({
    validationSchema: EventIntakeModalSchema({
      isCourseSelectionExists: !!props.intakeConfig?.useFocusTopicCourseSelect
    }),
    initialValues: getDefaultValues(),
    onSubmit: values => {
      createEventIntakeMutation({
        ...values,
        seatAssignmentId: props.seatAssignmentId
      });
    }
  });

  useEffect(() => {
    formik.resetForm({
      values: getDefaultValues()
    });
  }, [isLoadingGrades, isLoadingPositions]);

  const {
    mutate: createEventIntakeMutation,
    isLoading: isLoadingCreation
  } = useMutation(
    async (values: any) => {
      return await createEventIntake(values);
    },
    {
      onSuccess: data => {
        console.log(data);
        props.afterSubmit();
        // props.onClose();
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const intake = props.eventIntakeModalAssignment?.intake;

  const {
    mutate: submitIntakeMutation,
    isLoading: isLoadingSubmitIntake
  } = useMutation(
    async () => {
      if (!intake?.id) {
        throw new Error('Intake not found');
      }
      return await submitEventIntake(intake?.id);
    },
    {
      onSuccess: data => {
        props.afterSubmit();
        props.onClose();
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const isIntakeSubmitDisabled = !intake || !!intake?.submittedAt;

  return (
    <>
      <div
        className="h-screen w-0  sm:w-1/4 md:w-1/2 lg:w-2/3 fixed bg-black opacity-30 z-10 top-0 bottom-0 left-0 overflow-hidden"
        onClick={props.onClose}
      />
      <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
        <FormWrapper
          className="px-8 py-4"
          formik={formik}
          isLoading={isLoadingCreation}
          onCancel={props.onClose}
          customBtns={
            <>
              <Button
                type="button"
                text="Submit Intake"
                onClick={() => submitIntakeMutation()}
                isLoading={isLoadingSubmitIntake}
                disabled={isIntakeSubmitDisabled}
              />
            </>
          }
        >
          <div className="mb-4">
            <div className="flex justify-between mb-4">
              <h3 className="text-primary font-bold text-2xl">
                Complete Event Intake
              </h3>
              <button
                type="button"
                className="text-3xl"
                onClick={props.onClose}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-primary hover:text-primary-dark cursor-pointer"
                />
              </button>
            </div>
            <p className="text-gray-700">
              Use this form to complete the intake for the seat assignee.
            </p>
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4 mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="h-5 w-5 text-yellow-400"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-yellow-700 sans-serif">
                    If this attendee is to receive CTLE credit, please ensure
                    that Date of Birth and Last four SSN digits are set in their
                    profile
                  </p>
                  <p className="text-yellow-700 sans-serif">
                    (Applicable to New York State only)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-gray-600 sans-serif font-bold">
            About the Attendee
          </p>
          <FormField<IForm>
            name="firstName"
            type="text"
            label="First Name"
            required
          />
          <FormField<IForm>
            name="lastName"
            type="text"
            label="Last Name"
            required
          />
          <FormField<IForm> name="email" type="text" label="Email" required />
          <FormField<IForm>
            name="position"
            type="select"
            isLoading={isLoadingPositions}
            options={
              positionsData?.positions?.map(p => ({
                value: p.name,
                label: p.label
              })) || []
            }
            required
          />
          <FormField<IForm>
            name="grades"
            type="select"
            isLoading={isLoadingGrades}
            isMulti
            options={
              gradesData?.grades?.map(g => ({
                value: g.name,
                label: g.name
              })) || []
            }
            required
          />
          <div className="mt-6">
            <p className="text-gray-600 sans-serif font-bold mb-4">
              Event Preferences
            </p>
            {props.intakeConfig?.useFocusTopicCourseSelect && (
              <FormField<IForm>
                name="focusTopicCourseSelection"
                type="select"
                options={props.focusTopicCourseOptions}
                required
              />
            )}
            <div className="mt-2 flex flex-col gap-2">
              {(props.intakeFields || []).map((field: any) => (
                <FormField<IForm>
                  key={field.id}
                  name={`fields.${field.id}.value` as any}
                  type="text"
                  label={field.label}
                  placeholder={field.placeholder || 'Enter your response'}
                />
              ))}
            </div>
          </div>
          {isIntakeSubmitDisabled && (
            <p className="text-sm text-gray-500 italic">
              Please save your intake to enable submission. Once you have
              completed your intake, you may use the submit button below.
            </p>
          )}
        </FormWrapper>
      </aside>
    </>
  );
};

export default EventIntakeModal;
