import React, { useContext, useState } from 'react';
import {
  faCalendarAlt,
  faFile,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SEO from '../../components/Seo';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import Layout from '../Layout';
import './../layout.css';
import './LayoutCommon.css';
import { getEventDateRange } from './../../util';
import { Link, navigate } from 'gatsby';
import Button from '../common/Button';
import { Formik } from 'formik';
import { Form } from 'formik';
import { FormInput } from '../common/FormInput';
import { createProductPurchase } from '../members/api';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { ProductPurchaseData } from '../members/api';
import { Label } from '../common/FormInput';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

type Offering = {
  title: string;
  startDate?: string;
  endDate?: string;
  slug?: string;
  download?: {
    file: {
      contentType: string;
      url: string;
    };
  };
};
interface OfferingLinkProps {
  offering: Offering;
  children: React.ReactChild;
}

const isEvent = (offering: Offering): boolean =>
  !!offering.startDate && !!offering.endDate;

const OfferingLink: React.FC<OfferingLinkProps> = props => {
  if (isEvent(props.offering)) {
    return <Link to={`/events/${props.offering.slug}`}>{props.children}</Link>;
  } else {
    return (
      <a href={props.offering?.download?.file?.url} target="_blank">
        {props.children}
      </a>
    );
  }
};

interface OfferingsProps {
  offering: Offering;
}

const Offering: React.FC<OfferingsProps> = props => {
  return (
    <div className="text-gray-700 py-4 border-b">
      <OfferingLink offering={props.offering}>
        <div className="flex items-center mb-2 text-primary">
          <FontAwesomeIcon
            className="mr-4"
            icon={
              isEvent(props.offering)
                ? faCalendarAlt
                : props.offering?.download?.file?.contentType ===
                  'application/pdf'
                ? faFilePdf
                : faFile
            }
          />
          <div className="flex flex-col">
            <p className="font-semibold sans-serif hover:underline">
              {props.offering?.title}
            </p>
            {props.offering.startDate && props.offering.endDate && (
              <p className="text-sm text-gray-600">
                {getEventDateRange(
                  new Date(props.offering.startDate),
                  new Date(props.offering.endDate)
                )}
              </p>
            )}
          </div>
        </div>
      </OfferingLink>
    </div>
  );
};

interface PageContentProps {
  pageData: any;
}

const PageContent = (props: PageContentProps) => {
  return (
    <>
      {props.pageData?.featuredImage && (
        <div
          className="rounded-t-lg h-96 w-full bg-cover"
          style={{
            backgroundImage: props.pageData?.featuredImage
              ? `url(${props.pageData?.featuredImage?.fluid?.src})`
              : ''
          }}
        ></div>
      )}
      <section className="bg-white rounded-lg shadow-lg p-12">
        <h1 className="font-bold text-3xl text-gray-800">
          {props.pageData?.title}
        </h1>
        {/* {props.pageData?.showBuyNowButton && (
          <div className="mt-4">
            <a href={props.pageData?.purchaseLink}>
              <Button text="Purchase Now" />
            </a>
          </div>
        )} */}
        <section className="mt-10 content-body">
          <ContentfulContent content={props.pageData?.body?.body} />
        </section>
      </section>
    </>
  );
};

const UpcomingEvent = (props: any) => {
  return (
    <Link to={`/events/${props.upcomingEvent.slug}`}>
      <div className="bg-white rounded-lg shadow mt-4 p-4 cursor-pointer flex">
        <div className="ml-4">
          <p className="text-sm text-gray-700 font-semibold sans-serif">
            {props.upcomingEvent.title}
          </p>
          <p className="text-xs text-gray-500 uppercase mt-1">
            {getEventDateRange(
              new Date(props.upcomingEvent.startDate),
              new Date(props.upcomingEvent.endDate)
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};

const UpcomingEvents = (props: any) => {
  return (
    <>
      {props.upcomingEvents.map((event: Event, i: number) => {
        return <UpcomingEvent key={i} upcomingEvent={event} />;
      })}
    </>
  );
};

const ProductPurchaseSchema = Yup.object().shape({
  seats: Yup.number()
    .required('Number of seats is required')
    .min(1, 'Must purchase at least 1 seat')
    .max(100, 'Cannot purchase more than 100 seats'),
  paymentMethod: Yup.string().required('Payment method is required'),
  productId: Yup.string().required('Product ID is required')
});

const PageLayout = ({ pageContext }: any) => {
  const auth = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log('the page data', pageContext?.pageData);
  return (
    <Layout>
      <ToastContainer />
      <SEO title={pageContext?.pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 h-full mr-4">
            <PageContent pageData={pageContext?.pageData} />
          </div>
          <div className="flex flex-col w-full lg:w-1/3 h-screen lg:ml-4">
            <div className="sticky top-0 -mt-5">
              {pageContext?.pageData?.isProductPage &&
                pageContext?.pageData?.productId && (
                  <section>
                    {auth.authState ? (
                      <section className="mt-6 p-4 border border-gray-200 rounded-md bg-gray-100 shadow-md">
                        <p className="text-gray-600 mb-4">
                          Please select the number of seats you would like to
                          purchase.
                        </p>
                        <Formik
                          validationSchema={ProductPurchaseSchema}
                          initialValues={{
                            productId: pageContext?.pageData?.productId,
                            seats: 1,
                            paymentMethod: 'CREDIT_CARD'
                          }}
                          onSubmit={async values => {
                            try {
                              setIsSubmitting(true);
                              const {
                                productPurchase
                              } = await createProductPurchase(
                                values as ProductPurchaseData
                              );
                              setIsSubmitting(false);
                              navigate(
                                `/members/product-purchases/${productPurchase.id}`
                              );
                            } catch (err) {
                              toast.error(
                                'Something went wrong. Please contact support for assistance.'
                              );
                            }
                          }}
                        >
                          {({ setFieldValue }) => (
                            <Form>
                              <section className="grid grid-cols-2 gap-4">
                                <div>
                                  <div className="my-1.5">
                                    <Label text="Number of Seats" />
                                  </div>
                                  <FormInput
                                    size="md"
                                    type="number"
                                    placeholder="Number of Seats"
                                    name="seats"
                                    ariaLabel="Number of Seats"
                                  />
                                </div>
                                <div className="mt-2">
                                  <div className="mb-1">
                                    <Label text="Payment Method" />
                                  </div>
                                  <Select
                                    name="paymentMethod"
                                    ariaLabel="Payment Method"
                                    defaultValue={{
                                      value: 'CREDIT_CARD',
                                      label: 'Credit Card'
                                    }}
                                    options={[
                                      {
                                        value: 'CREDIT_CARD',
                                        label: 'Credit Card'
                                      },
                                      {
                                        value: 'PURCHASE_ORDER',
                                        label: 'Purchase Order'
                                      }
                                    ]}
                                    onChange={option => {
                                      setFieldValue(
                                        'paymentMethod',
                                        option?.value
                                      );
                                    }}
                                  />
                                </div>
                              </section>
                              <div className="mt-4">
                                <p className="text-sm text-gray-500 italic">
                                  Need a quote document? Proceed to the next
                                  screen for download.
                                </p>
                              </div>
                              <div className="mt-6">
                                <Button
                                  type="submit"
                                  text="Purchase Now"
                                  isLoading={isSubmitting}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </section>
                    ) : (
                      <section className="mt-6 p-4 border border-gray-200 rounded-md bg-gray-100 shadow-md">
                        <p className="text-gray-600 mb-4">
                          To purchase this product, please login to your
                          account.
                        </p>
                        <Button
                          text="Login to Purchase"
                          size="sm"
                          onClick={() => {
                            const path = window.location.pathname;
                            navigate(`/auth/login?intended=${path}`);
                          }}
                        />
                      </section>
                    )}
                  </section>
                )}
              {pageContext?.pageData?.offerings?.length && (
                <div className="mb-4 lg:mb-8">
                  <aside className="bg-white rounded-lg shadow-md p-8 mt-4 lg:mt-0">
                    <p className="text-2xl font-bold text-gray-600 sans-serif mb-4">
                      Offerings
                    </p>
                    <>
                      {pageContext?.pageData?.offerings?.map(
                        (o: any, i: number) => (
                          <Offering offering={o} key={i} />
                        )
                      )}
                    </>
                  </aside>
                </div>
              )}
            </div>
            {pageContext.pageData.slug ===
            'special-topic-institutes-2022-2023' ? (
              <div className="w-full mt-6 lg:mt-0">
                <h2 className="text-primary mb-4 font-bold text-xl">
                  Upcoming Events
                </h2>
                <UpcomingEvents upcomingEvents={pageContext.upcomingEvents} />
              </div>
            ) : null}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default PageLayout;
