export enum OwningEntity {
  LLC = 'LLC',
  TC = 'TC'
}

export enum TravelCost {
  NO_COST = 'NO_COST',
  FIXED_COST = 'FIXED_COST',
  ACTUAL_COST = 'ACTUAL_COST'
}

export enum PackageLocale {
  LOCAL = 'LOCAL',
  AFFILIATE = 'AFFILIATE'
}

export enum OrganizationMembershipRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER'
}

export enum BillingDeliveryMethod {
  PHYSICAL = 'PHYSICAL',
  EMAIL = 'EMAIL'
}

export type IEventIntake = {
  id: string;
  responses: any;
  status: 'APPROVED' | 'PENDING' | 'REJECTED' | 'SUBMITTED';
  submittedAt?: string;
};

export type ISeatAssignment = {
  id: string;
  user: IUser;
  userId: string;
  productId: string;
  intake: IEventIntake;
};

export type IProductPrice = {
  id: string;
  price: number;
  isDefault: boolean;
  discountSeatThreshold: number;
  discountAfterThresholdPercent: number;
};

export type IProduct = {
  id: string;
  name: string;
  description: string;
  prices: IProductPrice[];
};

export type IProductPurchase = {
  id: string;
  price: IProductPrice;
  product: IProduct;
  seatAssignments: ISeatAssignment[];
  organization: IOrganization;
  paymentMethod: string;
  paymentStatus: string;
  createdAt: string;
  updatedAt: string;
};

export type IEventPurchase = {
  id: string;
  price: IProductPrice;
  event: IEvent;
  seatAssignments: ISeatAssignment[];
  organization: IOrganization;
  paymentMethod: string;
  paymentStatus: string;
  createdAt: string;
  updatedAt: string;
};

export interface IOrganizationAddress {
  id: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateProvince?: string;
  zipPostal?: string;
  country?: string;
  phone?: string;
}

export interface IOrganization {
  id: string;
  name: string;
  district: any; //District
  districtId?: string;
  organizationType?: IOrganizationType;
  organizationTypeId?: string;
  profilePhoto?: string;
  bannerPhoto?: string;
  portalMetadata?: any;
  owningEntity: OwningEntity;
  packageConsumerGroups?: any[]; // PackageConsumerGroup[]
  packageRequests?: IPackageRequest[]; // PackageRequest[]
  dayAssignments?: any[]; //            DayAssignment[]
  contracts?: any[]; //             Contract[]
  memberships?: IOrganizationMembership[]; //             OrganizationMembership[]
  billingContacts?: IBillingContact[];
  purchaseOrders?: any[]; //            PurchaseOrder[]
  userOrganizationInvites?: any[]; //      UserOrganizationInvite[]
  credits?: any[]; //     Credit[]
  visibleDistrictMemberships?: any[]; //  DistrictMembership[]
  isProjectSchool?: boolean;
  principalsAttendConferences?: boolean;
  code?: string;
  isActive?: boolean;
  mailingAddress?: IOrganizationAddress;
  mailingAddressId?: string;
  billingAddress?: IOrganizationAddress;
  billingAddressId?: string;
  travelCost?: TravelCost;
  fixedCostAmount?: number; //   Int?
  actualCostAmount?: number; // Int?
  isNycDoe: boolean;
  ticketRecipientId?: string;
  ticketRecipient?: IOrganizationMembership;
  createdAt: string; //DateTime
  updatedAt: string; //DateTime
  archivedAt?: string; // DateTime?
}

export interface IPackageRequest {
  id: string;
  organization?: IOrganization;
  organizationId: string;
  package?: IPackage;
  packageId: string;
  initiator?: IUser;
  userId: string;
}

export interface IEvent {
  id: string;
  name: string;
  description: string;
  policy: string;
}

export interface IPackage {
  id: string;
  name: string;
  schoolYear: any; // SchoolYear?
  schoolYearId?: string;
  description: string;
  profilePhoto?: string;
  bannerPhoto?: string;
  owningEntity: OwningEntity;
  requireMinimumAmount: boolean;
  minimumAmount?: number; // Int?
  staffDeveloperDayFee: number; //Int
  seniorStaffDeveloperDayFee: number; //Int
  projectLeaderDayFee: number; //Int
  travelCost: TravelCost;
  fixedCostAmount?: number; //Int
  actualCostAmount?: number; //Int
  packageLocale: PackageLocale;
  pdSessions?: any[]; // PackageSchoolBasedPDSession[]
  events?: any[]; // PackageEvent[]
  groups?: any[]; //PackageGroup[]
  institutes?: any[]; //PackageInstitute[]
  notifications?: any[]; //PackageNotification[]
  model?: any[]; //PackageModel
  packageModelId: string;
  packageRequests?: any[]; // PackageRequest[]
  consumerGroups?: any[]; // PackageConsumerGroup[]
  createdAt: string; // DateTime
  updatedAt: string; // DateTime
  archivedAt?: string; // DateTime?
  publishedAt?: string; // DateTime?
}

export interface IOrganizationMembership {
  id: string;
  organization?: IOrganization;
  organizationId: string;
  user?: IUser; //User
  userId: string;
  role: OrganizationMembershipRole;
  grades?: IGrade[];
  positionId?: string;
  position?: IPosition;
  createdAt: string; // DateTime
  updatedAt: string; // DateTime
  archivedAt?: string; // DateTime?
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  authProviderId?: string;
  profilePhoto?: string;
  bannerPhoto?: string;
  address1?: string;
  address2?: string;
  city?: string;
  stateProvince?: string;
  zipPostal?: string;
  country?: string;
  phone?: string;
  dateOfBirth?: string; //  DateTime?
  last4SSN?: number; //  Int?
  background?: string;
  contentAccess?: IContentAccess;
  eventTickets?: any[]; //   EventTicket[]
  packageRequests?: any[]; //  PackageRequest[]
  eventCheckIns?: any[]; //  EventCheckIn[]
  eventApplications?: any[]; //  EventApplication[]
  eventTicketRequests?: any[]; //  EventTicketRequest[]
  groupSectionTicket?: any[]; //  GroupSectionTicket[]
  purchaseOrders?: any[]; //  PurchaseOrder[]
  userOrganizationInvites?: any[]; //  UserOrganizationInvite[]
  userDistrictInvites?: any[]; //  UserDistrictInvite[]
  notifications?: any[]; //  Notification[]
  refreshToken?: any; //  RefreshToken?
  districtMemberships?: any[]; //  DistrictMembership[]
  organizationMemberships?: any[]; // OrganizationMembership[]
  overridenPackageRequestInstitutes?: any[]; //  PackageRequestInstitute[]
  overridenPackageRequestInstituteGrades?: any[]; //  PackageRequestInstituteGrade[]
  overridenPackageRequestSchoolBasedPDSessions?: any[]; // PackageRequestSchoolBasedPDSession[]
  overridenPackageRequestEvents?: any[]; // PackageRequestEvent[]
  isActivated: boolean;
  createdAt: string; // DateTime
  updatedAt: string; // DateTime
  archivedAt?: string; // DateTime?
  overridenPackageRequestGroups?: any[]; //PackageRequestGroup[]
}

export interface IContentAccess {
  id: string;
  userId: string;
  toolKitAccess: boolean;
  schoolLeaderAccess: boolean;
  createdAt: string; //DateTime
  updatedAt: string; //DateTime
  user: IUser;
}

export interface IBillingContact {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateProvince?: string;
  zipPostal?: string;
  country?: string;
  invoiceDelivery: BillingDeliveryMethod;
  contractDelivery: BillingDeliveryMethod;
  organizationId: string;
  organization?: IOrganization;
  createdAt: string; //  DateTime
  updatedAt: string; //   DateTime
  archivedAt?: string; //   DateTime?
}

export interface IPosition {
  id: string;
  name: string;
  label: string;
}

export interface IGrade {
  id: string;
  name: string;
  events?: any[];
}

export interface IOrganizationType {
  id: string;
  label: string;
}

export interface IDistrict {
  id: string;
  name: string;
}
