import {
  faCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { formatAsCurrency } from '../../util';
import {
  EventPurchaseProps,
  formatPaymentMethod,
  formatPaymentStatus,
  paymentMethodOptions
} from '../../util/products';
import Button from '../common/Button';
import Card from '../common/Card';
import { FormInput } from '../common/FormInput';
import SEO from '../Seo';
import {
  deleteEventPurchase,
  getAffiliatedOrganizations,
  getAffiliatedUsers,
  getCashnetPaymentLinkForEvent,
  getEventPurchase,
  updateEventPurchase,
  updateSeatAssignmentsForEventPurchase
} from './api';
import EventIntakeModal from './EventIntakeModal';
import InfoBlock from './InfoBlock';
import InfoBlockEdit from './InfoBlockEdit';
import { IEventPurchase, ISeatAssignment } from './NewApiTypes';
import UserSelect from './UserSelect';

const eventTotalHasDiscount = (eventPurchase: IEventPurchase) => {
  const targetPrice = eventPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return false;
  }
  const discountSeatThreshold = targetPrice.discountSeatThreshold;
  const discountAfterThresholdPercent =
    targetPrice.discountAfterThresholdPercent;
  const totalSeats = eventPurchase.seatAssignments.length;
  return (
    totalSeats >= discountSeatThreshold && discountAfterThresholdPercent > 0
  );
};

const getEventPriceBeforeDiscount = (eventPurchase: IEventPurchase) => {
  const targetPrice = eventPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  return (targetPrice.price / 100) * eventPurchase.seatAssignments.length;
};

const getDiscountAfterThresholdPercent = (eventPurchase: IEventPurchase) => {
  const targetPrice = eventPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  return targetPrice.discountAfterThresholdPercent;
};

const getEventPurchaseTotalCost = (eventPurchase: IEventPurchase) => {
  const targetPrice = eventPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  const discountSeatThreshold = targetPrice.discountSeatThreshold;
  const discountAfterThresholdPercent =
    targetPrice.discountAfterThresholdPercent;
  const totalSeats = eventPurchase.seatAssignments.length;
  const totalPriceBeforeDiscount = (targetPrice.price / 100) * totalSeats;
  if (
    totalSeats >= discountSeatThreshold &&
    discountAfterThresholdPercent > 0
  ) {
    return totalPriceBeforeDiscount * (1 - discountAfterThresholdPercent / 100);
  }
  return totalPriceBeforeDiscount;
};

const getDefaultAssignedUser = (
  assignment: ISeatAssignment,
  affiliatedUsers: any[]
) => {
  const user = affiliatedUsers.find(user => user.id === assignment.user?.id);
  if (!user) {
    return null;
  }

  return {
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
    email: user.email
  };
};

const getAvailableUsersToAssign = (
  seatAssignments: any[],
  affiliatedUsers: any[]
) => {
  const availableUsers = affiliatedUsers.filter(
    user => !seatAssignments.some(sa => sa.userId === user.id)
  );

  return availableUsers.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
    email: user.email
  }));
};

const getDefaultOrganization = (
  organizations: any[],
  currentOrganizationId: string
) => {
  const matchedOrg = organizations.find(
    org => org.organization.id === currentOrganizationId
  );
  if (matchedOrg) {
    return {
      label: matchedOrg.organization.name,
      value: matchedOrg.organization.id
    };
  }
  return null;
};

const EventPurchase = (props: EventPurchaseProps) => {
  const [
    eventIntakeModalAssignment,
    setEventIntakeModalAssignment
  ] = useState<ISeatAssignment | null>(null);
  const { isFetching, error, data, refetch } = useQuery(
    'eventPurchase',
    () => getEventPurchase(props.eventPurchaseId),
    {
      onSuccess: data => {
        if (data.eventPurchase) {
          setSeatAssignments(
            data.eventPurchase.seatAssignments.map((sa: any) => ({
              seatAssignmentId: sa.id,
              userId: sa.user?.id ?? null
            }))
          );
          if (eventIntakeModalAssignment) {
            const updatedAssignment = data.eventPurchase.seatAssignments.find(
              (sa: any) => sa.id === eventIntakeModalAssignment.id
            );
            setEventIntakeModalAssignment(updatedAssignment);
          }
        }
      }
    }
  );
  const { data: affiliatedUsers } = useQuery('affiliatedUsers', () =>
    getAffiliatedUsers()
  );

  const { data: organizations } = useQuery(
    'organizations',
    getAffiliatedOrganizations
  );

  const { mutate: deletePurchase } = useMutation(
    async (eventPurchaseId: string) => {
      return await deleteEventPurchase(eventPurchaseId);
    },
    {
      onSuccess: () => {
        navigate('/members/events/purchases');
      },
      onError: () => {
        toast.error('There was an error deleting your purchase');
      }
    }
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isUpdatingSeats, setIsUpdatingSeats] = useState(false);
  const [seatAssignments, setSeatAssignments] = useState([]);

  return (
    <>
      <SEO title="My Event Purchase" />
      {isFetching && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {data && data.status === 'NOT_FOUND' && (
        <Card>
          <div>{data.error}</div>
        </Card>
      )}
      {data && data.eventPurchase && (
        <>
          <h3 className="text-primary font-bold text-2xl mb-6">
            Manage Your Event Purchase
          </h3>
          <Card>
            <>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <h2 className="text-primary font-bold text-xl">
                    {data.eventPurchase.event.name}
                  </h2>
                  <section className="mb-4">
                    <div
                      className="text-gray-600 w-full sm:w-2/3"
                      dangerouslySetInnerHTML={{
                        __html: data.eventPurchase.event.description
                      }}
                    />
                  </section>
                  <Formik
                    initialValues={{
                      organizationId: data.eventPurchase?.organization?.id,
                      paymentMethod: data.eventPurchase.paymentMethod,
                      seats: data.eventPurchase.seatAssignments.length
                    }}
                    onSubmit={async values => {
                      try {
                        await updateEventPurchase(
                          data.eventPurchase.id,
                          values
                        );
                        setIsEdit(false);
                        refetch();
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  >
                    {({ errors, touched, setFieldValue, handleChange }) => (
                      <Form>
                        <section className="grid grid-cols-1 sm:grid-cols-6 gap-3">
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Organization">
                                <Select
                                  defaultValue={getDefaultOrganization(
                                    organizations.organizationMemberships,
                                    data.eventPurchase.organization?.id
                                  )}
                                  options={organizations.organizationMemberships.map(
                                    (org: any) => ({
                                      label: org.organization.name,
                                      value: org.organization.id
                                    })
                                  )}
                                  onChange={value => {
                                    if (value) {
                                      setFieldValue(
                                        'organizationId',
                                        value.value
                                      );
                                    }
                                  }}
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Organization"
                                info={
                                  data.eventPurchase.organization
                                    ? [data.eventPurchase.organization.name]
                                    : []
                                }
                              />
                            )}
                          </div>
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Seats">
                                <FormInput
                                  size="md"
                                  ariaLabel="Seats"
                                  placeholder="Seats"
                                  type="number"
                                  name="seats"
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Seats"
                                info={[
                                  data.eventPurchase.seatAssignments.length
                                ]}
                              />
                            )}
                          </div>
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Payment Method">
                                <Select
                                  options={paymentMethodOptions}
                                  defaultValue={paymentMethodOptions.find(
                                    option =>
                                      option.value ===
                                      data.eventPurchase.paymentMethod
                                  )}
                                  onChange={value => {
                                    if (value) {
                                      setFieldValue(
                                        'paymentMethod',
                                        value.value
                                      );
                                    }
                                  }}
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Payment Method"
                                info={[
                                  formatPaymentMethod(
                                    data.eventPurchase.paymentMethod
                                  )
                                ]}
                              />
                            )}
                          </div>
                          <div>
                            <InfoBlock
                              title="Payment Status"
                              info={[
                                formatPaymentStatus(
                                  data.eventPurchase.paymentStatus
                                )
                              ]}
                            />
                          </div>
                        </section>

                        {data.eventPurchase.paymentStatus !== 'COMPLETED' && (
                          <div className="flex w-full mt-4">
                            {isEdit ? (
                              <div className="mr-2">
                                <Button
                                  color="success"
                                  type="submit"
                                  text="Save Purchase Details"
                                  size="sm"
                                />
                              </div>
                            ) : (
                              <>
                                <div className="mr-2 mt-4">
                                  <Button
                                    type="button"
                                    text="Edit Purchase Details"
                                    size="sm"
                                    onClick={e => {
                                      e.preventDefault();
                                      setIsEdit(true);
                                    }}
                                  />
                                </div>
                                <div className="mr-2 mt-4">
                                  <Button
                                    type="button"
                                    color="error"
                                    text="Delete Purchase"
                                    size="sm"
                                    onClick={async e => {
                                      e.preventDefault();
                                      if (
                                        confirm(
                                          'Are you sure you want to delete this purchase?'
                                        )
                                      ) {
                                        await deletePurchase(
                                          data.eventPurchase.id
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>

                  <section className="mt-8">
                    <h3 className="text-primary font-bold mb-4 text-lg">
                      Payment
                    </h3>
                    <div>
                      <div className="mb-2">
                        {getEventPurchaseTotalCost(data.eventPurchase) ===
                        null ? (
                          <p className="text-red-500 italic">
                            Ticket pricing error. Please contact support.
                          </p>
                        ) : (
                          <section className="grid grid-cols-6 gap-3 mb-4">
                            {eventTotalHasDiscount(data.eventPurchase) && (
                              <>
                                <div>
                                  <InfoBlock
                                    infoClassNames={['line-through']}
                                    title="Original Price"
                                    info={[
                                      `${formatAsCurrency(
                                        getEventPriceBeforeDiscount(
                                          data.eventPurchase
                                        ) ?? 0
                                      )}`
                                    ]}
                                  />
                                </div>
                                <div>
                                  <InfoBlock
                                    title="Volume Discount"
                                    info={[
                                      `${getDiscountAfterThresholdPercent(
                                        data.eventPurchase
                                      )}%`
                                    ]}
                                  />
                                </div>
                              </>
                            )}
                            <div>
                              <InfoBlock
                                title="Total Price"
                                info={[
                                  `${formatAsCurrency(
                                    getEventPurchaseTotalCost(
                                      data.eventPurchase
                                    ) ?? 0
                                  )}`
                                ]}
                              />
                            </div>
                          </section>
                        )}
                      </div>
                      {data.eventPurchase.paymentMethod === 'PURCHASE_ORDER' ? (
                        <>
                          {data.eventPurchase.paymentStatus !== 'COMPLETED' ? (
                            <>
                              {data.eventPurchase.organization ? (
                                <div className="w-1/2 px-3 py-2 bg-yellow-100 border border-yellow-300 rounded-md">
                                  <p className="text-gray-800 font-bold text-lg sans-serif mb-1">
                                    How to Submit Your Purchase Order
                                  </p>
                                  <p className="text-gray-800 font-bold sans-serif mb-1">
                                    Note: Please double-check the organizaiton
                                    set for the purchase above before submitting
                                    your purchase order
                                  </p>
                                  <p className="text-gray-700 sans-serif mb-2">
                                    Please email your purchase order (in PDF
                                    format) to{' '}
                                    <a
                                      href="mailto:al-finance@tc.columbia.edu"
                                      className="underline text-blue-500 whitespace-nowrap"
                                    >
                                      al-finance@tc.columbia.edu
                                    </a>
                                    .
                                  </p>
                                  <p className="text-gray-700 sans-serif mb-2">
                                    Please include purchase order number and
                                    organization name in the email subject line,
                                    and list the name of the event and persons
                                    covered in the email body. Purchase orders
                                    can be made out to:
                                  </p>
                                  <p className="text-gray-700 sans-serif mb-2 font-semibold">
                                    Advancing Literacy
                                    <br />
                                    Teachers College
                                    <br />
                                    525 West 120th Street, #77
                                    <br />
                                    New York, NY 10027
                                  </p>
                                  <p className="text-gray-700 sans-serif">
                                    Once your PO is received we'll finalize your
                                    acceptance and send the meeting details.
                                  </p>
                                  <div className="mt-4">
                                    <Button
                                      type="button"
                                      text="Download Quote"
                                      size="sm"
                                      onClick={() => {
                                        const link = document.createElement(
                                          'a'
                                        );
                                        link.href = '/tcal-quote.pdf';
                                        link.download = 'tcal-quote.pdf';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="w-1/2 px-3 py-2 bg-red-100 border border-red-300 rounded-md">
                                  <p className="text-gray-800 font-bold text-lg sans-serif mb-1">
                                    Organization Required
                                  </p>
                                  <p className="text-gray-700 sans-serif">
                                    Please set your organization above before
                                    proceeding with a purchase order submission.
                                    Click "Edit Purchase" to set the
                                    organization for this purchase.
                                  </p>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="w-1/2 px-3 py-2 bg-green-100 border border-green-300 rounded-md">
                              <p className="text-gray-800 font-bold text-lg sans-serif mb-1">
                                Purchase Order Received
                              </p>
                              <p className="text-gray-700 sans-serif">
                                Your purchase order has been received and
                                processed.
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {data.eventPurchase.paymentStatus !== 'COMPLETED' && (
                            <>
                              <Button
                                type="button"
                                text="Pay Now"
                                size="sm"
                                disabled={!data.eventPurchase.organization}
                                onClick={async () => {
                                  const {
                                    cashNetPaymentLink
                                  } = await getCashnetPaymentLinkForEvent(
                                    data.eventPurchase.id
                                  );
                                  window.location = cashNetPaymentLink;
                                }}
                              />
                              {!data.eventPurchase.organization && (
                                <div className="text-sm text-gray-500 italic mt-2">
                                  Organization must be set before assigning
                                  seats
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <div>
                        {data.eventPurchase.paymentMethod === 'NOT_SET' && (
                          <span className="text-xs text-gray-500 italic ml-2">
                            Payment method must be set before paying
                          </span>
                        )}
                      </div>
                    </div>
                  </section>

                  <section className="mt-8">
                    <h3 className="text-primary font-bold mb-4 text-lg">
                      Seat Assignments
                    </h3>
                    <section className="flex gap-6">
                      <div className="w-1/2">
                        <Formik
                          initialValues={{
                            seatAssignments: data.eventPurchase.seatAssignments.map(
                              (sa: ISeatAssignment) => ({
                                seatAssignmentId: sa.id,
                                userId: sa.user?.id || null
                              })
                            )
                          }}
                          onSubmit={async values => {
                            try {
                              if (
                                confirm(
                                  'Seat assignees will receive notifications of their assignments. Are you sure you want to proceed?'
                                )
                              ) {
                                await updateSeatAssignmentsForEventPurchase(
                                  data.eventPurchase.id,
                                  values
                                );
                                setIsUpdatingSeats(false);
                                refetch();
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              <div>
                                {data.eventPurchase.seatAssignments.map(
                                  (assignment: ISeatAssignment, i: number) => (
                                    <div key={assignment.id} className="mb-4">
                                      <label className="uppercase text-gray-500 text-sm sans-serif">
                                        Seat Assignee #{i + 1}
                                      </label>
                                      <>
                                        {isUpdatingSeats ? (
                                          <div className="">
                                            <UserSelect
                                              defaultValue={getDefaultAssignedUser(
                                                assignment,
                                                affiliatedUsers
                                              )}
                                              options={getAvailableUsersToAssign(
                                                values.seatAssignments,
                                                affiliatedUsers
                                              )}
                                              onChange={(value: any) => {
                                                const newAssignments = [
                                                  ...values.seatAssignments
                                                ];
                                                newAssignments[i].userId =
                                                  value?.value || null;
                                                setFieldValue(
                                                  'seatAssignments',
                                                  newAssignments
                                                );
                                              }}
                                            />
                                            <button
                                              type="button"
                                              className="text-red-500 text-sm"
                                              onClick={() => {
                                                const newAssignments = [
                                                  ...values.seatAssignments
                                                ];
                                                newAssignments[i].userId = null;
                                                setFieldValue(
                                                  'seatAssignments',
                                                  newAssignments
                                                );
                                              }}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="border border-gray-200 rounded-md p-2">
                                            {assignment.user ? (
                                              <div className="flex justify-between">
                                                <div>
                                                  {assignment.user.firstName &&
                                                  assignment.user.lastName ? (
                                                    <p className="text-gray-700 sans-serif">
                                                      {
                                                        assignment.user
                                                          .firstName
                                                      }{' '}
                                                      {assignment.user.lastName}
                                                    </p>
                                                  ) : (
                                                    <p className="text-gray-500 sans-serif italic">
                                                      Name not set
                                                    </p>
                                                  )}
                                                  <div className="flex justify-between">
                                                    <p className="text-gray-500 sans-serif text-sm">
                                                      {assignment.user.email}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="flex flex-col my-auto">
                                                  {!assignment.intake && (
                                                    <>
                                                      <div className="bg-yellow-100 border border-yellow-300 rounded-md p-2 mb-2">
                                                        <p className="sans-serif text-sm text-yellow-600">
                                                          <FontAwesomeIcon
                                                            icon={
                                                              faExclamationTriangle
                                                            }
                                                            className="mr-2"
                                                          />
                                                          Intake not received
                                                        </p>
                                                      </div>
                                                      <Button
                                                        onClick={() => {
                                                          setEventIntakeModalAssignment(
                                                            assignment
                                                          );
                                                        }}
                                                        type="button"
                                                        text="Complete Intake"
                                                        size="sm"
                                                        disabled={
                                                          !data?.eventPurchase
                                                            ?.event
                                                            ?.intakeConfig
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                  {assignment.intake &&
                                                    assignment.intake.status ===
                                                      'PENDING' && (
                                                      <>
                                                        <div className="bg-yellow-100 border border-yellow-300 rounded-md p-2 mb-2">
                                                          <p className="sans-serif text-sm text-yellow-600">
                                                            <FontAwesomeIcon
                                                              icon={
                                                                faExclamationTriangle
                                                              }
                                                              className="mr-2"
                                                            />
                                                            Intake not yet
                                                            submitted
                                                          </p>
                                                        </div>
                                                        <Button
                                                          onClick={() => {
                                                            setEventIntakeModalAssignment(
                                                              assignment
                                                            );
                                                          }}
                                                          type="button"
                                                          text="Update Intake"
                                                          size="sm"
                                                          disabled={
                                                            !data?.eventPurchase
                                                              ?.event
                                                              ?.intakeConfig
                                                          }
                                                        />
                                                      </>
                                                    )}
                                                  {assignment.intake &&
                                                    assignment.intake.status ===
                                                      'SUBMITTED' && (
                                                      <>
                                                        <div className="bg-green-100 border border-green-300 rounded-md p-2 mb-2">
                                                          <p className="sans-serif text-sm text-green-600">
                                                            <FontAwesomeIcon
                                                              icon={faCheck}
                                                              className="mr-2"
                                                            />
                                                            Intake submitted
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                </div>
                                              </div>
                                            ) : (
                                              <p className="text-gray-500 sans-serif italic">
                                                Not set
                                              </p>
                                            )}

                                            {assignment.intake?.responses
                                              ?.focusTopicCourseSelection ? (
                                              <div>
                                                <p className="text-gray-500 sans-serif text-sm">
                                                  Selected Focus Topic Courses
                                                </p>

                                                <ul className="pl-4">
                                                  <li className="text-gray-700 sans-serif list-disc">
                                                    {data.eventPurchase.event.focusTopicCourses
                                                      .map((course: any) => ({
                                                        label: course.name,
                                                        value: course.id
                                                      }))
                                                      .find(
                                                        (c: any) =>
                                                          c.value ===
                                                          assignment.intake
                                                            .responses
                                                            .focusTopicCourseSelection
                                                      )?.label || 'Not found'}
                                                  </li>
                                                </ul>
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </>
                                    </div>
                                  )
                                )}
                              </div>
                              <div>
                                {isUpdatingSeats ? (
                                  <Button
                                    type="submit"
                                    text="Save Seat Assignments"
                                    size="sm"
                                    color="success"
                                  />
                                ) : (
                                  <Button
                                    type="button"
                                    text="Update Seat Assignments"
                                    size="sm"
                                    disabled={
                                      data.eventPurchase.paymentStatus !==
                                        'COMPLETED' ||
                                      !data.eventPurchase.organization
                                    }
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setIsUpdatingSeats(true);
                                    }}
                                  />
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      {isUpdatingSeats && (
                        <section className="w-1/2 border border-blue-500 bg-blue-100 rounded-md p-4 h-full mt-6">
                          <p className="text-gray-600 sans-serif">
                            If the staff member you wish to assign to a seat is
                            not in the dropdown list, you can add an account for
                            the staff member first.{' '}
                          </p>
                          <div className="mt-3">
                            <Link to="/members/organization/select">
                              <Button
                                type="button"
                                text="Add New Staff Member"
                                size="sm"
                              />
                            </Link>
                          </div>
                        </section>
                      )}
                    </section>

                    <section>
                      {data.eventPurchase.paymentStatus !== 'COMPLETED' && (
                        <div className="text-sm text-gray-500 italic mt-2">
                          Payment must be completed before assigning seats
                        </div>
                      )}
                    </section>
                  </section>
                </div>
              </div>
            </>
          </Card>

          {eventIntakeModalAssignment && (
            <EventIntakeModal
              focusTopicCourseOptions={data.eventPurchase.event.focusTopicCourses.map(
                (course: any) => ({
                  label: course.name,
                  value: course.id
                })
              )}
              seatAssignmentId={eventIntakeModalAssignment.id}
              eventIntakeModalAssignment={eventIntakeModalAssignment}
              afterSubmit={refetch}
              onClose={() => {
                setEventIntakeModalAssignment(null);
              }}
              intakeConfig={data.eventPurchase.event.intakeConfig}
              intakeFields={data.eventPurchase.event.intakeFields || []}
            />
          )}
        </>
      )}
    </>
  );
};

export default EventPurchase;
