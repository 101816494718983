import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import Button from './common/Button';
import Layout from './Layout';
import { clearImpersonate } from './members/api/index';
import { User } from './members/NewApiTypes.generated';
import ProfilePhoto from './members/ProfilePhoto';

type Member = {
  name: string;
  school: string;
  avatar: string;
};

interface MemberSmallProfileProps {
  member: Member;
}

const MemberSmallProfile = (props: MemberSmallProfileProps) => {
  const auth = useContext(AuthContext);
  const logout = async () => {
    try {
      localStorage.removeItem('access_token');
      localStorage.removeItem('eventSectionsCart');

      if (auth.setAuthState) {
        auth.setAuthState(null);
        navigate('/auth/login');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex">
      <div className="w-20">
        <ProfilePhoto photo={props.member.avatar} />
      </div>
      <div className="ml-3">
        <p className="sans-serif text-primary text-xl font-bold">
          {props.member.name}
        </p>
        <p className="text-sm sans-serif text-gray-600">
          {props.member.school}
        </p>
        <div className="mt-2">
          <Button
            text="Log Out"
            size="sm"
            icon={faSignOutAlt}
            onClick={logout}
          />
        </div>
      </div>
    </div>
  );
};

type MembersLink = {
  label: string;
  link: string;
  external?: boolean;
  requireToolkit?: boolean;
};

const membersLinks: MembersLink[] = [
  {
    label: 'Profile',
    link: 'profile'
  },
  {
    label: 'My Toolkit',
    link: 'my-toolkit',
    requireToolkit: true
  },
  {
    label: 'My Saved Resources',
    link: 'my-saved-resources',
    requireToolkit: true
  },
  {
    label: 'Organization Services',
    link: 'organization/select'
  },
  {
    label: 'Manage Services',
    link: 'manage-services/select-organization'
  },
  {
    label: 'My Events',
    link: 'events/applications'
  },
  {
    label: 'My Event Purchases',
    link: 'events/purchases'
  },
  {
    label: 'My Products',
    link: 'product-purchases/my-products'
  }
];

interface MembersLinkContentProps {
  linkLabel: string;
}

const MembersLinkContent = (props: MembersLinkContentProps) => {
  return (
    <div className="border-b border-gray-200 py-4">
      <p className="text-primary sans-serif font-bold text-lg">
        {props.linkLabel}
      </p>
    </div>
  );
};

interface MembersLinkProps {
  link: MembersLink;
}

const MembersLink = (props: MembersLinkProps) => {
  if (props.link.external) {
    return (
      <a href={props.link.link}>
        <MembersLinkContent linkLabel={props.link.label} />
      </a>
    );
  }
  return (
    <Link to={`/members/${props.link.link}`}>
      <MembersLinkContent linkLabel={props.link.label} />
    </Link>
  );
};

interface MembersSidebarProps {
  user: User;
}
const MembersSidebar = (props: MembersSidebarProps) => {
  const auth = useContext(AuthContext);

  let user;

  if (auth.authState?.impersonator) {
    user = auth.authState.impersonator;
  } else {
    user = props.user;
  }
  return (
    <div className="bg-white p-8 h-full shadow-lg">
      <div className="mt-2 mb-10">
        <MemberSmallProfile
          member={{
            name: `${user.firstName} ${user.lastName}`,
            school: '',
            avatar: require(`./../images/user.png`)
          }}
        />
      </div>
      {membersLinks.map((link: MembersLink, i: number) => {
        if (link.requireToolkit && !auth.canAccessToolkit(user)) {
          return null;
        }
        return <MembersLink link={link} key={i} />;
      })}
    </div>
  );
};

interface LayoutProps {
  children: React.ReactNode;
  user: User;
}

const MembersLayout = (props: LayoutProps) => {
  const auth = useContext(AuthContext);

  const location = useLocation();

  if (!props.user && location.pathname !== '/auth/login') {
    navigate(`/auth/login?intended=${location.pathname}`);
    return null;
  }

  const [impersonationUser, setImpersonationUser] = useState(
    auth?.authState?.impersonator || null
  );

  return (
    <Layout>
      <div className="flex">
        <div style={{ width: '400px' }}>
          {props.user && <MembersSidebar user={props.user} />}
        </div>
        <div className="flex flex-col w-full">
          {impersonationUser && (
            <div className="flex flex-row top-0 w-full bg-primary py-2.5 pl-2 sm:pl-4 md:pl-10 font-bold text-blue-100 ml-0 sm:text-base md:text-xl ">
              <div>
                Viewing as: {impersonationUser.firstName}{' '}
                {impersonationUser.lastName}{' '}
                <span className="text-blue-200">
                  ({impersonationUser.email})
                </span>
              </div>

              <Button
                text="Clear Impersonation"
                size="sm"
                className="ml-4 md:ml-12"
                onClick={async () => {
                  try {
                    await clearImpersonate(auth.authState?.impersonator);
                    try {
                      localStorage.removeItem('access_token');

                      if (auth.setAuthState) {
                        auth.setAuthState(null);
                        navigate('/auth/login');
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </div>
          )}
          <section className={`p-8 w-full ${impersonationUser ? 'pt-10' : ''}`}>
            {props.children}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default MembersLayout;
