import { Link } from 'gatsby';
import React from 'react';
import { useQuery } from 'react-query';
import { formatDates } from '../../util/date';
import { formatPaymentMethod, formatPaymentStatus } from '../../util/products';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { getEventPurchasesForInitiator } from './api';
import { IEventPurchase } from './NewApiTypes';

interface MyEventsProps {
  path: string;
}
const MyEvents = (props: MyEventsProps) => {
  const {
    isFetching: isEventPurchasesFetching,
    error: eventPurchasesError,
    data: eventPurchasesData,
    refetch: refetchEventPurchases
  } = useQuery('eventPurchases', () => getEventPurchasesForInitiator());

  return (
    <>
      <SEO title="My Event Purchases" />
      <Card>
        <h3 className="text-primary font-bold text-2xl mb-6">
          My Event Purchases
        </h3>
        {isEventPurchasesFetching ? <LoadingDialog /> : null}
        {!eventPurchasesData?.eventPurchases?.length &&
          !isEventPurchasesFetching && (
            <span className="italic sans-serif text-gray-400">
              No events found
            </span>
          )}
        {eventPurchasesError && (
          <span className="italic sans-serif text-gray-400">
            There was an error retrieving your events. Please try again later.
          </span>
        )}
        <section>
          {eventPurchasesData && eventPurchasesData.eventPurchases.length ? (
            <ul>
              {eventPurchasesData.eventPurchases.map(
                (purchase: IEventPurchase) => {
                  return (
                    <li
                      key={purchase.id}
                      className="mb-4 flex justify-between gap-4 border-b border-gray-200 pb-4"
                    >
                      <div className="w-3/4">
                        <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                          {purchase.event.name}
                        </p>
                        <div className="flex gap-1 mb-2">
                          <label
                            htmlFor="purchase-date"
                            className="sans-serif text-gray-500"
                          >
                            Purchase Date:
                          </label>
                          <p className="sans-serif text-gray-700">
                            {formatDates([purchase.createdAt])}
                          </p>
                        </div>
                        <p className="sans-serif text-gray-500">
                          {purchase.event.description?.substring(0, 250)}
                          {purchase.event.description?.length > 250
                            ? '...'
                            : ''}
                        </p>
                      </div>
                      <div className="w-1/4">
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-col">
                            <span className="text-sm text-gray-500 sans-serif">
                              Payment Status:
                            </span>
                            <span className="text-sm font-medium sans-serif">
                              {formatPaymentStatus(purchase.paymentStatus)}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm text-gray-500 sans-serif">
                              Payment Method:
                            </span>
                            <span className="text-sm font-medium sans-serif">
                              {formatPaymentMethod(purchase.paymentMethod)}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm text-gray-500 sans-serif">
                              Seats:
                            </span>
                            <span className="text-sm font-medium sans-serif">
                              {
                                purchase.seatAssignments.filter(sa => sa.user)
                                  .length
                              }{' '}
                              / {purchase.seatAssignments.length} Assigned
                            </span>
                          </div>
                          <Link to={`/members/events/purchases/${purchase.id}`}>
                            <Button size="sm" text="Manage Purchase" />
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          ) : null}
        </section>
      </Card>
    </>
  );
};

export default MyEvents;
