const React = require('react');
const { AuthProvider } = require('./src/context/AuthContext');
const { PackageProvider } = require('./src/context/PackageContext');
const { ApplicationProvider } = require('./src/context/ApplicationContext');
const addScript = url => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.id = 'ze-snippet';
  document.body.appendChild(script);
};

const addScriptWithContent = content => {
  const script = document.createElement('script');
  script.innerHTML = content;
  script.async = true;
  script.id = 'pardot';
  document.body.appendChild(script);
};

export const onInitialClientRender = () => {
  // comment this in to add the zendesk widget
  if (process.env.GATSBY_USE_ZENDESK_WIDGET === 'true') {
    addScript(
      'https://static.zdassets.com/ekr/snippet.js?key=31d4b2bb-10ed-4567-a371-810acaa857a4'
    );
  }
};

export const wrapRootElement = ({ element, props }) => {
  return (
    <AuthProvider>
      <PackageProvider>
        <ApplicationProvider>{element}</ApplicationProvider>
      </PackageProvider>
    </AuthProvider>
  );
};
